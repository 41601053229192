import { DirectionsRun } from "@mui/icons-material";
import { Button } from "@mui/material";

interface CalculateProps {
  handleCalculate: () => void;
}

export function Calculate(props: CalculateProps){
  return (
    <Button 
          variant="contained" 
          onClick={props.handleCalculate}
          fullWidth
          sx={{
            backgroundColor: 'rgb(17, 23, 29)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgb(22, 28, 35)',
              boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.7)',
            },
            gap: 1,
            borderRadius: '50px',
            height: '56px',
            zIndex: 3,
            boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.7)',
            '&:active': {
              boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)'
            },
          }}            
        >
          <DirectionsRun/>
          Calculate
        </Button>
  )
}