import styled from "@emotion/styled";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button, TextField, Checkbox, ListItemText, Box, Chip, OutlinedInput, SelectProps} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React, { useState, useRef, useEffect } from 'react';

export const BlackTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused': {
        color: 'black',
      },
    },
    '& .MuiOutlinedInput-input': {
      '&:focus': {
        color: 'black',
      },
    },
  }));
  
  export const BlackSelect = styled(Select)<SelectProps>(() => ({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
    },
    '&.Mui-focused .MuiSelect-icon': {
      color: 'black',
    },
    '& .MuiMenu-paper': {
      '& .MuiMenuItem-root': {
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  }));
    
export const BlackChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-deletable .MuiChip-deleteIcon': {
      color: 'rgba(0, 0, 0, 0.26)',
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.4)',
      },
    },
  }));
  
export const BlackCheckbox = styled(Checkbox)(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.6)',
    '&.Mui-checked': {
      color: 'black',
    },
  }));
  
export const BlackListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  }));
  
  const CompactTableCell = styled(TableCell)({
    padding: '8px 12px',  // Increased padding for better spacing
    fontSize: '1rem',     // Increased font size from 0.8rem to 1rem
  });
  
  const CompactTableHeaderCell = styled(TableCell)({
    padding: '10px 12px',  // Slightly more padding for header cells
    fontSize: '1.1rem',    // Slightly larger font for headers
    fontWeight: 'bold',    // Make headers bold for emphasis
  });
  
  const CompactIconButton = styled(IconButton)({
    padding: 6,  // Slightly increased padding for better touch targets
  });
  
  const TrashCell = styled(CompactTableCell)({
    width: '50px',  // Increased width to accommodate larger delete button
    padding: '0 6px',
  });  
  
  const ScrollableTableContainer = styled(TableContainer)({
    overflowX: 'auto',
    scrollBehavior: 'smooth',
  });
  
  const ArrowButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.03)', // Lighter background
    padding: '12px', // Increased padding for a larger touch area
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)', // Slightly darker on hover
    },
    transition: 'all 0.3s ease',
  }));
  
  const ArrowIcon = styled('span')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.5)', // Slightly lighter color for the arrow
    '& svg': {
      fontSize: '1.8rem', // Slightly larger icon
    },
  });    
  
  interface DestinationTableProps {
    tableData: (string | number)[][];
    handleBoxClick: (col: number) => void;
    highlightColumn: number | null;
    handleDeleteDestination: (index: number) => void;
  }
  
  export const DestinationTable: React.FC<DestinationTableProps> = ({
    tableData,
    handleBoxClick,
    highlightColumn,
    handleDeleteDestination
  }) => {
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);
    const tableRef = useRef<HTMLDivElement>(null);
  
    const checkScrollable = () => {
      if (tableRef.current) {
        const { scrollWidth, clientWidth } = tableRef.current;
        setIsScrollable(scrollWidth > clientWidth);
      }
    };
  
    const handleScroll = () => {
      if (tableRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = tableRef.current;
        setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
        setShowLeftArrow(scrollLeft > 0);
      }
    };
  
    const scrollRight = () => {
      if (tableRef.current) {
        tableRef.current.scrollLeft += tableRef.current.clientWidth;
      }
    };
  
    const scrollLeft = () => {
      if (tableRef.current) {
        tableRef.current.scrollLeft -= tableRef.current.clientWidth;
      }
    };
  
    useEffect(() => {
      const tableElement = tableRef.current;
      if (tableElement) {
        checkScrollable();
        handleScroll();
        tableElement.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', checkScrollable);
        return () => {
          tableElement.removeEventListener('scroll', handleScroll);
          window.removeEventListener('resize', checkScrollable);
        };
      }
    }, []);
  
    useEffect(() => {
      checkScrollable();
    }, [tableData]);
  
    const [nonEmptyCols, setNonEmptyCols] = useState<number[]>([]);
  
    useEffect(() => {
      if (tableData.length === 0) {
        return;
      }
  
      const dataRows = tableData.slice(1);
  
      const nonEmptyCols: Set<number> = new Set();
      const numRows = dataRows.length;
      const numCols = dataRows[0]?.length || 0;
      
      for (let colIndex = 0; colIndex < numCols; colIndex++) {
        let isColumnEmpty = true;
        
        for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
          if (dataRows[rowIndex][colIndex] !== undefined) {
            isColumnEmpty = false;
            break;
          }
        }
        
        if (!isColumnEmpty) {
          nonEmptyCols.add(colIndex);
        }
      }

      setNonEmptyCols(Array.from(nonEmptyCols));
    }, [tableData]);

    return (
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <ScrollableTableContainer ref={tableRef}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {tableData[0].map((cell, col) => (
                  nonEmptyCols.includes(col) && (<CompactTableHeaderCell 
                    key={col} 
                    onClick={() => handleBoxClick(col)}
                    style={highlightColumn === col ? { backgroundColor: 'rgb(50, 50, 50, 0.18)' } : {}}
                  >
                    {cell}
                  </CompactTableHeaderCell>
                )))}
                <TrashCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.slice(1).map((row, i) => (
                <TableRow key={i}>
                  {row.map((col, j) => (
                    (nonEmptyCols.includes(j)) && (<CompactTableCell 
                      key={j} 
                      onClick={() => handleBoxClick(j)}
                      style={highlightColumn === j ? { backgroundColor: 'rgb(50, 50, 50, 0.18)'} : {}}
                    >
                      {col}
                    </CompactTableCell>
                  )))}
                  <TrashCell>
                    <CompactIconButton onClick={() => handleDeleteDestination(i + 1)} aria-label="delete">
                      <DeleteIcon />
                    </CompactIconButton>
                  </TrashCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollableTableContainer>
        {isScrollable && showRightArrow && (
        <ArrowButton
          onClick={scrollRight}
          style={{ right: 0 }} // Moved back to the edge
          size="large"
        >
          <ArrowIcon>
            <ArrowForwardIosIcon />
          </ArrowIcon>
        </ArrowButton>
      )}
      {isScrollable && showLeftArrow && (
        <ArrowButton
          onClick={scrollLeft}
          style={{ left: 0 }} // Moved back to the edge
          size="large"
        >
          <ArrowIcon>
            <ArrowBackIosNewIcon />
          </ArrowIcon>
        </ArrowButton>
      )}
      </div>
    );
  };
  
const AddMoreAddressesButtonStyle = styled(Button)({
  backgroundColor: 'rgb(17, 23, 29)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgb(22, 28, 35)',
    boxShadow: '3px 3px 7px rgba(0, 0, 0, 0.4)',
  },
  width: '250px', // Adjust the width as needed
  height: '30px', // Adjust the height to make it smaller
  boxShadow: '3px 3px 7px rgba(0, 0, 0, 0.4)',
  '&:active': {
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)'
  },
});
  
interface AddMoreAddressesButtonProps {
  onClick: () => void;
}
  
export const AddMoreAddressesButton: React.FC<AddMoreAddressesButtonProps> = ({ onClick }) => {
  return (
    <AddMoreAddressesButtonStyle variant="contained" onClick={onClick}>
      Add More Addresses
    </AddMoreAddressesButtonStyle>
  );
};
  