import React, { ReactNode, useState, useEffect } from 'react';
import { 
  TextField, 
  List, 
  ListItem, 
  styled,
  Box
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface EditableItem {
  id: string;
  value: string;
}

interface EditableTextFieldProps {
  initialValue: string;
  index: number;
  onBoxClick: (index: number) => void;
  label: ReactNode;
  setOriginAddress: (index: number, value: string) => void;
  isHighlighted: boolean;
}

const HighlightedTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted',
})<{ isHighlighted: boolean }>(({ isHighlighted }) => ({
  '& .MuiOutlinedInput-root': {
    fontWeight: isHighlighted ? 'bold' : 'normal',
    '& fieldset': {
      borderColor: isHighlighted ? 'black' : 'rgba(0, 0, 0, 0.23)',
      borderWidth: isHighlighted ? 2 : 1,
    },
    '&:hover fieldset': {
      borderColor: isHighlighted ? 'black' : 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  '& .MuiInputLabel-root': {
    fontWeight: isHighlighted ? 'bold' : 'normal',
    color: isHighlighted ? 'black' : 'rgba(0, 0, 0, 0.6)',
    '&.Mui-focused': {
      color: 'black',
    },
  },
  '& .MuiOutlinedInput-input': {
    '&:focus': {
      color: 'black',
    },
  },
}));

const EditableTextField: React.FC<EditableTextFieldProps> = ({ initialValue, index, onBoxClick, label, setOriginAddress, isHighlighted }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setOriginAddress(index, event.target.value);
  };

  return (
    <HighlightedTextField
      label={label}
      value={value}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      onClick={() => onBoxClick(index + 1)}
      isHighlighted={isHighlighted}
    />
  );
};

interface EditableListProps {
  items: EditableItem[];
  onBoxClick: (index: number) => void;
  setOriginAddress: (index: number, value: string) => void;
  highlightColumn: number;
  showAddMoreAddressButton: boolean;
}

const EditableList: React.FC<EditableListProps> = ({ items, onBoxClick, setOriginAddress, highlightColumn, showAddMoreAddressButton}) => {
  return (
      <List>
        {items.map((item, index) => (
          (showAddMoreAddressButton ? (index < 2 && 
          <ListItem key={item.id}>
            <EditableTextField 
              initialValue={item.value} 
              index={index} 
              onBoxClick={onBoxClick}
              label={
                <Box display="flex" alignItems="center">
                  <LocationOnIcon fontSize="small" style={{ marginRight: '4px', color: 'rgb(70, 80, 90)' }} />
                  Address {index + 1}
                </Box>
              }
              setOriginAddress={setOriginAddress}
              isHighlighted={highlightColumn === index + 1}
            />
          </ListItem>
          )
          : 
          <ListItem key={item.id}>
          <EditableTextField 
            initialValue={item.value} 
            index={index} 
            onBoxClick={onBoxClick}
            label={
              <Box display="flex" alignItems="center">
                <LocationOnIcon fontSize="small" style={{ marginRight: '4px', color: 'rgb(70, 80, 90)' }} />
                Address {index + 1}
              </Box>
            }
            setOriginAddress={setOriginAddress}
            isHighlighted={highlightColumn === index + 1}
          />
        </ListItem>
          )
        ))}
      </List>
  );
};

export default EditableList;