import { Button, IconButton } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './ToolBar.css'

export interface ToggleButtonProps {
  isOverlayVisible: boolean;
  setIsOverlayVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ isOverlayVisible, setIsOverlayVisible }) => {
  return (
    <IconButton
      onClick={() => setIsOverlayVisible(!isOverlayVisible)}
      sx={{
        zIndex: 3,
        backgroundColor: 'rgb(17, 23, 29)',
        color: 'white',
        '&:hover': {
          backgroundColor: '#333',
          boxShadow: '3px 3px 7px rgba(0, 0, 0, 0.4)',
        },
        '&:active': {
          boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)'
        },
        boxShadow: '3px 3px 7px rgba(0, 0, 0, 0.4)',
      }}
    >
      {isOverlayVisible ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );
};  

interface ToolBarProps {
  isOverlayVisible: boolean;
  setIsOverlayVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleClear: () => void;
}

export default function ToolBar(ToolBarProps: ToolBarProps) {
  const { 
    isOverlayVisible, 
    setIsOverlayVisible,
    handleClear
  } = ToolBarProps;
  return (
    <div className='tool-bar'>
      <ToggleButton 
        isOverlayVisible={isOverlayVisible} 
        setIsOverlayVisible={setIsOverlayVisible} 
      />
      <Button
        className="clear-button"
        onClick={handleClear}
      >
        Clear Map
      </Button>
    </div>
  )
}