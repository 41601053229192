import { ReactNode, useEffect, useState } from 'react';
import './App.css';
import EditableList from './EditableList';
import { Box, useMediaQuery } from '@mui/material';
import Map from './Map';
import { LocationAddressType, OriginAddressType, OriginDestinationPair, OriginDestinationTimes, RouteType } from './Types';
import { SelectChangeEvent } from '@mui/material';
import ToolBar from './ToolBar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useHideKeyboardOnTouchMove from './useHideKeyboardOnTouchMove';
import toast, { Toaster } from 'react-hot-toast';
import { Calculate } from './Calculate';
import TravelModes from './TravelModes';
import PointsOfInterest from './PointsOfInterest';
import RotatingWords from './RotatingTitle';
import { AddMoreAddressesButton } from './Themes';

function App() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  useHideKeyboardOnTouchMove();

  const [originAddresses, setOriginAddresses] = useState<OriginAddressType[]>([
    { id: '1', value: 'Nob Hill Hotel' },
    { id: '2', value: 'Ritz Carlton, San Francisco' },
    { id: '3', value: '' },
    { id: '4', value: '' },
  ]);

  const [locations, setLocations] = useState<LocationAddressType[]>([
    {name: "Beach", address: "Fisherman's Wharf"},
    {name: "Office", address: "Embarcadero, San Francisco"},
  ]);

  const [highlightColumn, setHighlightColumn] = useState<number>(1);
  const [showAddMoreAddressButton, setShowMoreAddressButton] = useState<boolean>(true);

  const updateOriginAddress = (index: number, value: string) => {
    setOriginAddresses(prevAddresses => {
      const newAddresses = [...prevAddresses];
      newAddresses[index] = { ...newAddresses[index], value };
      return newAddresses;
    });
  };

  const generateInitialTableData = (locations: LocationAddressType[]) => {
    const header = ["", ...originAddresses.map((_, index) => (
      <Box display="flex" alignItems="center">
        <LocationOnIcon fontSize="small" style={{ marginRight: '4px', color: 'rgb(70, 80, 90)' }} />
        {index + 1}
      </Box>
    ))];
    const rows = locations.map(location => [location.name, ...Array(originAddresses.length).fill("")]);
    return [header, ...rows];
  };
  

  const [tableData, setTableData] = useState(() => generateInitialTableData(locations));

  const [newLocationName, setNewLocationName] = useState('');
  const [newLocationAddress, setNewLocationAddress] = useState('');
  const [originDestionationTimes, setOriginDestinationTimes] = useState<OriginDestinationTimes>({});
  const [disableAddDestination, setDisableAddDestination] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  useEffect(() => {
    toast('Find where to stay anywhere in the 🌎', {
      duration: 5500,
    })
  }, [])

  useEffect(() => {
    const header = ["", ...originAddresses.map((_, index) => (
      <Box display="flex" alignItems="center">
        <LocationOnIcon fontSize="small" style={{ marginRight: '4px', color: 'rgb(70, 80, 90)' }} />
        {index + 1}
      </Box>
    ))];
    const rows: string[][] = []
      if (originDestinationMatrix.length > 0) {
      originDestinationMatrix[0].forEach((pair, j) => {
        const x= [pair.destination.name]
        rows.push(x)
      })

      originDestinationMatrix.forEach((originRow, i) => {
        originRow.forEach((pair, j) => {
          const key = `${pair.toKey()}` as string
          const time: string = originDestionationTimes[key]?.time;
          rows[j].push(time)
        })
      })
      setTableData([header, ...rows])
    }
  }, [originDestionationTimes])

  const handleBoxClick = (index: number) => {
    console.log(index);
    if (index === 0) {
      setHighlightColumn(-1);
    } else {
      setHighlightColumn(index);
    }
  };

  const handleDeleteDestination = (index: number) => {
    setDisableAddDestination(false);
    setLocations(prevLocations => prevLocations.filter((_, i) => i !== index - 1));
    setTableData(prevData => prevData.filter((_, i) => i !== index));
  };

  
  const handleAddDestination = () => {
    if (newLocationName.trim() !== '') {
      setLocations(prevLocations => [...prevLocations, { name: newLocationName, address: newLocationAddress }]);
      
      // Update tableData state
      setTableData(prevData => {
        const newRow = [newLocationName, ...Array(prevData[0].length - 1).fill(undefined)];
        return [...prevData, newRow];
      });
      if (locations.length + 1 >= 5) {
        setDisableAddDestination(true);
      }
      setNewLocationName('');
      setNewLocationAddress('');
    }
  };

  const [selectedRouteTypes, setSelectedRouteTypes] = useState<RouteType>(['WALKING', 'TRANSIT']);

  const handleTravelModeChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const value = event.target.value as string | string[];
    setSelectedRouteTypes(typeof value === 'string' ? [value] as RouteType : value as RouteType);
  };
  
  const handleTravelModeDelete = (travelMode: string) => {
    setSelectedRouteTypes(prev => prev.filter(mode => mode !== travelMode));
  };

  const handleClear = () => {
    const clearedOriginAddresses = [
      { id: '1', value: '' },
      { id: '2', value: '' },
      { id: '3', value: '' },
      { id: '4', value: '' },
    ];
    setOriginAddresses(clearedOriginAddresses);
    setLocations([]);
    setTableData(generateInitialTableData([]));
    setOriginDestinationTimes({});
    setDisableAddDestination(false);
    setSelectedRouteTypes(['WALKING', 'TRANSIT']);
    setHighlightColumn(1);
    setNewLocationName('');
    setNewLocationAddress('');
    setOriginDestinationMatrix([]);
  };

  const initializeOriginDestinationMatrix = (selectedRouteTypes: RouteType): OriginDestinationPair[][] => {
    return originAddresses.map(origin => {
      return locations.map(location => {
        return new OriginDestinationPair(
          origin,
          location,
          selectedRouteTypes,
        );
      });
    });
  };

  const [originDestinationMatrix, setOriginDestinationMatrix] = useState<OriginDestinationPair[][]>(initializeOriginDestinationMatrix(['WALKING', 'TRANSIT']));

  const handleCalculate = () => {
    if (isMobile) {
      setIsOverlayVisible(!isOverlayVisible);
    }
    setOriginDestinationMatrix(initializeOriginDestinationMatrix(selectedRouteTypes));
  }; 

  return (
    <div className="container">
      <div><Toaster /></div>
      <div className="map-container">
        <Map 
          originDestinationMatrix={originDestinationMatrix} 
          selectedColumn={highlightColumn}
          originDestinationTimes={originDestionationTimes}
          setOriginDestinationTimes={setOriginDestinationTimes}   
        />
      </div>

      <div className="left-container" style={{height: '100dvh', justifyContent: 'space-between' }}>

        <div className='top'>
          <ToolBar
            isOverlayVisible={isOverlayVisible}
            setIsOverlayVisible={setIsOverlayVisible}
            handleClear={handleClear}
          />
          <div className={`overlay-container ${isOverlayVisible ? 'visible' : ''}`} style={{maxHeight: '93%'}}>
            <div className="box">
              <RotatingWords firstString="Deciding on " secondString="?" words={['hotels', 'apartments', 'airbnbs', 'homes']}/>
              <EditableList items={originAddresses} onBoxClick={handleBoxClick} setOriginAddress={updateOriginAddress} highlightColumn={highlightColumn} showAddMoreAddressButton={showAddMoreAddressButton}/>
              <div style={{textAlign: 'center'}}>
                {showAddMoreAddressButton && <AddMoreAddressesButton onClick={() => setShowMoreAddressButton(false)}/>}
              </div>
            </div>
            <div className="box">
              <PointsOfInterest
                tableData={tableData}
                handleBoxClick={handleBoxClick}
                highlightColumn={highlightColumn}
                handleDeleteDestination={handleDeleteDestination}
                newLocationName={newLocationName}
                setNewLocationName={setNewLocationName}
                newLocationAddress={newLocationAddress}
                setNewLocationAddress={setNewLocationAddress}
                handleAddDestination={handleAddDestination}
                disableAddDestination={disableAddDestination}
              />
            </div>
            <div className='box calculate-box'>
              <TravelModes
                selectedRouteTypes={selectedRouteTypes}
                handleTravelModeChange={handleTravelModeChange}
                handleTravelModeDelete={handleTravelModeDelete}
              />
            </div>
            <div className='box' style={{textAlign:'center', padding: '10px'}}>
              <>routecanvas.app by Robert Hou and <a href="https://www.samuelfu.com/">Samuel Fu</a></>
            </div>
          </div>
        </div>

        <div className='calculateContainer' style={{paddingTop: '10px'}}>
          <Calculate handleCalculate={handleCalculate}/>
        </div>

      </div>
    </div>
  );
}

export default App;
