import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react'
import { 
  GoogleMap, 
  useJsApiLoader, 
  DirectionsRenderer,
  DirectionsService,
  Marker,
  OverlayView
} from '@react-google-maps/api';
import {
  OriginDestinationPair,
  OriginDestinationTimes
} from './Types'
import toast, { Toaster } from 'react-hot-toast';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 40.73,
  lng: -73.99
};
const colors = [
  '#4285F4',  // Google Maps blue
  '#5B95F5',  // Slightly lighter blue
  '#3066BE',  // Darker blue
  '#7BAAF7',  // Light pastel blue
  '#1E4D92',  // Deep blue
  '#9FC1FA'   // Very light blue
];

interface MapWrapperProps {
  originDestinationMatrix: OriginDestinationPair[][];
  selectedColumn: number;
  originDestinationTimes: OriginDestinationTimes,
  setOriginDestinationTimes: any
}

const useDirectionsRequests: any = (hasErrorRef: React.MutableRefObject<boolean>) => {
  const requestedPairs = useRef(new Set<string>());
  const directionsData = useRef<any>({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    fetch('/directions_data.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        directionsData.current = data;
        setIsDataLoaded(true);
      })
      .catch(error => {
        console.error('Error loading directions data:', error);
        directionsData.current = {};
        setIsDataLoaded(true);  // Set to true even on error so the app can proceed
      });
  }, []);

  const saveDirectionsToFile = (data: any) => {
    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'directions_data.json';
    link.click();
    URL.revokeObjectURL(url);
    console.log('Directions data saved to file');
  };

  const requestDirections = useCallback((pair: OriginDestinationPair, travelMode: string, originDestinationTimes: any, setOriginDestinationTimes: any, directions: any, setDirections: any) => { 
    const key = `${pair.origin.value}-${pair.destination.address}-${travelMode}`;
    if (!isDataLoaded) {
      return null;  // Don't make a request yet
    }

    // Check if we have cached data
    if (directionsData.current[key]) {
      const cachedResponse = directionsData.current[key];
      setDirections((prev: any) => ({ ...prev, [pair.toKey()]: cachedResponse }));
      originDestinationTimes[pair.toKey()] = {
        origin: pair.origin.value,
        destination: pair.destination.address,
        time: cachedResponse.routes[0].legs[0].duration?.text,
        travelMode: travelMode
      };
      setOriginDestinationTimes(JSON.parse(JSON.stringify(originDestinationTimes)));
      return null;
    }

    
    if (requestedPairs.current.has(key) && pair.toKey() in directions) {
      const prevTime = directions[pair.toKey()].routes[0].legs[0].duration?.text as string;
      originDestinationTimes[pair.toKey()] = {
        origin: pair.origin.value,
        destination: pair.destination.address,
        time: prevTime,
        travelMode: travelMode
      }
      setOriginDestinationTimes(JSON.parse(JSON.stringify(originDestinationTimes)));
      return null;
    };
    requestedPairs.current.add(key);
    return (
      <DirectionsService
      key={key}
        options={{
          destination: pair.destination.address,
          origin: pair.origin.value,
          travelMode: travelMode as unknown as google.maps.TravelMode
        }}
        callback={(response: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus) => {
          if (status === 'OK' && response) {
            setDirections((prev: any) => {
              const prevDuration = prev[pair.toKey()]?.routes[0]?.legs[0]?.duration?.value ?? Infinity;
              const newDuration = response.routes[0].legs[0].duration?.value as number;
              if (newDuration < prevDuration) {
                originDestinationTimes[pair.toKey()] = {
                  origin: pair.origin.value,
                  destination: pair.destination.address,
                  time: response.routes[0].legs[0].duration?.text as string,
                  travelMode: travelMode
                }
                setOriginDestinationTimes(JSON.parse(JSON.stringify(originDestinationTimes)));

                // Save the new direction data
                directionsData.current[key] = response;
                // Uncomment the following line during development to save the file
                // saveDirectionsToFile(directionsData.current);
                
                return { ...prev, [pair.toKey()]: response };
              }
              return prev;
            });
          } else if (!hasErrorRef.current && status === 'NOT_FOUND') {
            toast(
              (t) => (
                <span>
                  Hint: Adding the city can help us determine the address.
                  EX: Embarcadero, <b>San Francisco</b>
                </span>
              ), {
                duration: 8000
              }
            );
            toast.error(`The origin "${pair.origin.value}" or the destination "${pair.destination.address}" cannot be determined. ` +
              `Please provide a more specific address`, {
                duration: 8000
              }); 
            hasErrorRef.current = true;
          } else if (!hasErrorRef.current) {
            if (status === 'OVER_QUERY_LIMIT') {
              toast.error('You have exceeded your daily request quota for this website. Please try again later.');
            } else {
              toast.error(`Directions request failed. Status: ${status}`);
            }
            console.error(`Directions request failed. Status: ${status}`);
            hasErrorRef.current = true;
          }      
        }}
      />
    );
  }, [isDataLoaded]);

  return requestDirections;
};

const MapWrapper: React.FC<MapWrapperProps> = React.memo(({
  originDestinationMatrix, 
  selectedColumn,
  originDestinationTimes,
  setOriginDestinationTimes
}) => {
  const [selectedColumnDirections, setSelectedColumnDirections] = useState<{ [key: string]: {directionsResult: google.maps.DirectionsResult, color: string, pair: OriginDestinationPair} }>({});

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const hasErrorRef = useRef(false);
  const [directions, setDirections] = useState<{ [key: string]: google.maps.DirectionsResult }>({});
  const requestDirections = useDirectionsRequests(hasErrorRef);

  useEffect(() => {
    setOriginDestinationTimes(originDestinationTimes);
  }, [originDestinationTimes]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string
  });

  const directionRequests = useMemo(() => {
    if (!isLoaded) return [];
    hasErrorRef.current = false;
    
    const allKeys: string[] = [];
    originDestinationMatrix.flatMap(
      row => row.flatMap(
        pair => {
          const key = `${pair.origin.value}-${pair.destination.address}` as string
          if (pair && pair.origin.value && pair.destination) {
            allKeys.push(key);
          }
        }
      )
    )

    // Delete keys from originDestinationTimesCopy that are not in allKeys
    for (const key in originDestinationTimes) {
      if (!allKeys.includes(key)) {
        delete originDestinationTimes[key];
      }
    }
    setOriginDestinationTimes(JSON.parse(JSON.stringify(originDestinationTimes))) 

    return originDestinationMatrix.flatMap(row => 
      row.flatMap(pair => {
        if (pair && pair.origin.value && pair.destination) {
          return pair.travelModes.map(travelMode => requestDirections(pair, travelMode, originDestinationTimes, setOriginDestinationTimes, directions, setDirections));
        }
        return [];
      })
    ).filter(Boolean);
  }, [originDestinationMatrix, requestDirections, isLoaded]);

  useEffect(() => {
    console.log('Origin-Destination Matrix updated:', originDestinationMatrix);
    console.log('selectedColumn ', selectedColumn);
  }, [originDestinationMatrix, selectedColumn]);

  useEffect(() => {
    const newSelectedColumnDirections: { [key: string]: {directionsResult: google.maps.DirectionsResult, color: string, pair: OriginDestinationPair} } = {};
    originDestinationMatrix[selectedColumn - 1]?.forEach((pair) => {
      if (pair) {
        const key = pair.toKey();
        if (directions[key]) {
          newSelectedColumnDirections[key] = {
            directionsResult: directions[key],
            color: colors[selectedColumn - 1],
            pair: pair
          };
        }
      }
    });
    setSelectedColumnDirections(newSelectedColumnDirections);
  }, [directions, selectedColumn, originDestinationMatrix]);

  const formatAddress = (address: string) => {
    const parts = address.split(',').map(part => part.trim());
    if (parts.length === 1) return [parts[0]];
    if (parts.length === 2) return parts;
    return [parts.slice(0, -1).join(', '), parts[parts.length - 1]];
  };

  const WrappedTextMarker: React.FC<{
    position: google.maps.LatLng,
    text: string,
    type: string
  }> = ({ position, text, type }) => {
    const formattedText = formatAddress(text);
    const backgroundColor = type === 'origin' ? '#4285F4' : '#EA4335';
    
    return (
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div style={{
          background: backgroundColor,
          color: 'white',
          padding: '6px 8px',
          borderRadius: '4px',
          fontWeight: 'bold',
          fontSize: '13px',
          lineHeight: '1.2',
          maxWidth: '200px',
          minWidth: '100px',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
          position: 'absolute',
          transform: 'translate(-50%, -100%)',
          marginTop: '-10px'
        }}>
          {formattedText.map((line, index) => (
            <React.Fragment key={index}>
              {index > 0 && <br />}
              {line}
            </React.Fragment>
          ))}
        </div>
      </OverlayView>
    );
  };


  const renderedDirections = useMemo(() => 
    Object.values(selectedColumnDirections).map((currResponse: {directionsResult: google.maps.DirectionsResult, color: string, pair: OriginDestinationPair}, index: number) => (
      <React.Fragment key={index}>
        <DirectionsRenderer
          options={{
            directions: currResponse.directionsResult,
            polylineOptions: {
              strokeColor: currResponse.color,
              strokeWeight: 4
            },
            suppressMarkers: true,
            suppressBicyclingLayer: true,
          }}
        />
        <WrappedTextMarker
          position={currResponse.directionsResult.routes[0].legs[0].start_location}
          text={currResponse.pair.origin.value}
          type="origin"
        />
        <WrappedTextMarker
          position={currResponse.directionsResult.routes[0].legs[0].end_location}
          text={currResponse.pair.destination.name}
          type="destination"
        />
      </React.Fragment>
    )),
    [selectedColumnDirections]
  );

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    bounds.extend({ lat: 40.712776, lng: -74.105974 });
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <><div><Toaster /></div>
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        mapTypeControl: false,
        streetViewControl: false,  // This hides the Pegman
        zoomControl: false,        // This hides the zoom controls
        fullscreenControl: false,
      }}
    >
      {directionRequests}
      {renderedDirections}
    </GoogleMap></>
  ) : <></>;
});

export default MapWrapper;