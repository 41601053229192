import React, { useState, useEffect } from 'react';
import './App.css';

interface RotatingWordsProps {
    firstString: string;
    secondString: string;
    words: string[];
  }
  
const RotatingWords: React.FC<RotatingWordsProps> = ({firstString, secondString, words}) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      setKey((prevKey) => prevKey + 1); // Force re-render to reset animation
    }, 5000); // Change word every 5 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [words.length]);

  return (
      <h2>
        {firstString} <span key={key} className="rotating-word">{words[currentWordIndex]}</span>{secondString}
      </h2>
  );
};

export default RotatingWords;
