import { Button } from "@mui/material";
import { BlackTextField, DestinationTable } from "./Themes";
import RotatingWords from "./RotatingTitle";

interface PointsOfInterestProps {
  tableData: any;
  handleBoxClick: (col: number) => void;
  highlightColumn: number | null;
  handleDeleteDestination: (row: any) => void;
  newLocationName: string;
  setNewLocationName: React.Dispatch<React.SetStateAction<string>>;
  newLocationAddress: string;
  setNewLocationAddress: React.Dispatch<React.SetStateAction<string>>;
  handleAddDestination: () => void;
  disableAddDestination: boolean;
}

export default function PointsOfInterest(props: PointsOfInterestProps) {
  return (
    <div>
      <RotatingWords firstString="Your points of interest - " secondString="?" words={['museum', 'gym', 'beach', 'office']}/>
      <DestinationTable
        tableData={props.tableData}
        handleBoxClick={props.handleBoxClick}
        highlightColumn={props.highlightColumn}
        handleDeleteDestination={props.handleDeleteDestination}
      />
      <div className="add-row-container" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <BlackTextField
          label="Nickname"
          value={props.newLocationName}
          placeholder="Donuts"
          onChange={(e) => props.setNewLocationName(e.target.value)}
          style={{ marginRight: '10px' }}
          fullWidth
        />
        <BlackTextField
          label="Address"
          value={props.newLocationAddress}
          placeholder="Bob's Donuts, San Francisco"
          onChange={(e) => props.setNewLocationAddress(e.target.value)}
          style={{ marginRight: '10px' }}
          fullWidth
        />
        <Button 
          variant="contained" 
          onClick={props.handleAddDestination}
          sx={{
            backgroundColor: 'rgb(17, 23, 29)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgb(22, 28, 35)',
              boxShadow: '3px 3px 7px rgba(0, 0, 0, 0.4)',
            },
            height: '40px',
            boxShadow: '3px 3px 7px rgba(0, 0, 0, 0.4)',
            '&:active': {
              boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)'
            },
          }}
          disabled={props.disableAddDestination}
          >
          Save
        </Button>
      </div>
    </div>
  )
}
