export class OriginDestinationPair {
  origin: { id: string; value: string; };
  destination: LocationAddressType;
  travelModes: string[];

  constructor(origin: { id: string; value: string; }, destination: LocationAddressType, travelModes: string[]) {
    this.origin = origin;
    this.destination = destination;
    this.travelModes = travelModes;
  }

  toKey(): string {
    return `${this.origin.value}-${this.destination.address}-${this.travelModes.join('-')}`;
  }
};

export interface OriginDestinationTimes {
  [key: string]: DirectionsStats;
}

export interface DirectionsStats {
  origin: string;
  destination: string;
  time: string;
  travelMode: String;
}

export type RouteType = ('WALKING' | 'DRIVING' | 'TRANSIT' | 'BICYCLING')[];

export interface OriginAddressType {
  id: string;
  value: string;
}

export interface LocationAddressType {
  name: string;
  address: string;
}