import { useEffect } from 'react';

const useHideKeyboardOnTouchMove = () => {
  useEffect(() => {
    const hideKeyboard = () => {
      const activeElement = document.activeElement;
      if (activeElement instanceof HTMLElement && typeof activeElement.blur === 'function') {
        activeElement.blur();
      }
    };

    window.addEventListener('touchmove', hideKeyboard);

    return () => {
      window.addEventListener('touchmove', hideKeyboard);
    };
  }, []);
};

export default useHideKeyboardOnTouchMove;
