import { Box, FormControl, Grid, InputLabel, MenuItem, SelectChangeEvent } from "@mui/material";
import { BlackCheckbox, BlackChip, BlackListItemText, BlackSelect } from "./Themes";
import { ReactNode } from "react";

interface TravelModeProps {
  selectedRouteTypes: string[];
  handleTravelModeChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => any;
  handleTravelModeDelete: (travelMode: string) => void;
}

export default function TravelModes(props: TravelModeProps) {
  return (
    <div>
      <h2>How would you like to travel?</h2>
      <Grid container spacing={2} alignItems="center" paddingTop={0.5}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <BlackSelect
            labelId="route-select-label"
            id="route-select"
            multiple
            value={props.selectedRouteTypes}
            onChange={props.handleTravelModeChange}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', overflowX: 'auto', gap: 0.5 }}>
                {(selected as string[]).map((travelMode) => (
                  <BlackChip 
                    key={travelMode} 
                    label={travelMode} 
                    onMouseDown={(event) => { event.stopPropagation(); }}
                    onDelete={(event) => { props.handleTravelModeDelete(travelMode) }} />
                ))}
              </Box>
            )}
          >
            {['WALKING', 'DRIVING', 'TRANSIT', 'BICYCLING'].map((mode) => (
              <MenuItem key={mode} value={mode}>
                <BlackCheckbox checked={props.selectedRouteTypes.indexOf(mode as any) > -1} />
                <BlackListItemText primary={mode} />
              </MenuItem>
            ))}
          </BlackSelect>
        </FormControl>
      </Grid>
    </Grid>
  </div>
  )
}